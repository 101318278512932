import { IntegrationsPageData } from '../pages/integrations';
import { IntegrationService } from '../types';
import toFileMap from './toFileMap';

const allIntegrations: (data: IntegrationsPageData) => IntegrationService[] = ({
  logos: { edges: logoEdges },
  icons: { edges: iconEdges },
  markdowns: { edges: markdownEdges },
}) => {
  const logos = toFileMap(logoEdges, 'relativeDirectory');
  const icons = toFileMap(iconEdges, 'relativeDirectory');

  return markdownEdges
    .map(
      ({
        node: {
          html,
          fields: { slug },
          frontmatter,
        },
      }) => ({
        ...frontmatter,
        slug,
        logo: logos[slug]?.publicURL || icons[slug]?.publicURL,
        html,
      }),
    )
    .sort(({ name: n1 }, { name: n2 }) => (n1 < n2 ? -1 : 1));
};

export default allIntegrations;
